<template>
    <div style="padding: 10px">
        <div style="background: #fff; border-radius: 8px; padding: 20px;">
            <div class="query-c">
                查询：
                <Input search placeholder="请输入查询内容" style="width: auto" />
            </div>
            <br>
            <Table max-height="440"  border stripe :columns="columns1" :data="data1"  >
            </Table>
            <br>
    <Page :total="total" show-sizer show-elevator prev-text="上一页" @on-change="change" @on-page-size-change="pageSizeCall" next-text="下一页"/>
        </div>
    </div>
</template>

<script>
import { userList } from '@/api/user.js'

export default {
    name: 'userList',
    data() {
        return {
            total: 0,
            username: '',
            pageSize: 10,
            firstResult: 0,
            modalVisible: false,
            columns1: [
                {
                    title: '用户名',
                    key: 'username',
                },
                {
                    title: '邮箱',
                    key: 'email',
                },
                {
                    title: '头像',
                    key: 'userHead',
                    render: (h, params) => h('div', [
                        h('viewer', {
                            props: {
                                image: params.row.userHead,
                            },
                        }, [
                            h('img', {
                                attrs: {
                                    src: params.row.userHead,
                                },
                                style: {
                                    width: '80px',
                                    height: '80px',
                                },
                            }),
                        ]),
                    ]),
                },
                {
                    title: '性别',
                    key: 'sex',
                },
                {
                    title: '最后登录时间',
                    key: 'lastLogintime',
                },
                {
                    title: '最后登录ip',
                    key: 'lastLoginip',
                },
                {
                    title: '最后登录地址',
                    key: 'lastLoginAddress',
                },
                {
                    title: '生日',
                    key: 'birthday',
                },
                {
                    title: '简介',
                    key: 'motto',
                    render: (h, params) => h('div', [
                        // 显示的位置上方
                        h('Tooltip', {
                            props: { placement: 'top', transfer: true },
                        }, [
                            h('span', {
                                style: {
                                    display: 'inline-block',
                                    width: '100px',
                                    overflow: 'hidden',
                                    // 同样在这里隐藏溢出内容
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                },
                            }, params.row.motto),
                            // 显示的内容
                            h('span', {
                                slot: 'content',
                                style: { whiteSpace: 'normal', wordBreak: 'break-all' },
                            }, params.row.motto),
                        ]),
                    ]),
                },
                {
                    title: '背景',
                    key: 'homeBackground',
                    render: (h, params) => h('div', [
                        h('viewer', {
                            props: {
                                image: params.row.homeBackground,
                            },
                        }, [
                            h('img', {
                                attrs: {
                                    src: params.row.homeBackground,
                                },
                                style: {
                                    width: '80px',
                                    height: '80px',
                                },
                            }),
                        ]),
                    ]),
                },
                {
                    title: '创建时间',
                    key: 'createDatetime',
                },
                {
                    title: '操作',
                    key: 'Actions',
                    width: 150,
                    render: (h, params) => h('div', [
                        h(
                            'Button',
                            {
                                props: {
                                    type: 'primary',
                                    size: 'small',
                                },
                                style: {
                                    marginRight: '5px',
                                },
                                on: {
                                    click: () => {
                                        console.log('点击了编辑')
                                    },
                                },
                            },
                            '编辑'
                        ),
                        h(
                            'Button',
                            {
                                props: {
                                    type: 'error',
                                    size: 'small',
                                },
                                on: {
                                    click: async () => {
                                        // 弹出模态框
                                        this.$Modal.confirm({
                                            title: '提示',
                                            content: '确认删除',
                                            okText: '确认',
                                            cancelText: '取消',
                                            onOk: async () => {
                                                console.log('点击了删除的确认按钮')
                                            },
                                        })
                                    },
                                },
                            },
                            '删除'
                        ),
                    ]),
                },
            ],
            data1: [],
        }
    },
    mounted() {
        this.getPage()
    },
    methods: {
        pageSizeCall(val) {
            this.pageSize = val
        },
        calculateStartData(currentPage, pageSize) {
            let startData = (currentPage - 1) * pageSize
            return startData
        },
        change(val) {
            this.firstResult = this.calculateStartData(val, this.pageSize)
            this.getPage()
        },
        getPage() {
            userList({
                username: this.username,
                pageSize: this.pageSize,
                firstResult: this.firstResult,
                search: this.content,
            }).then(res => {
                this.data1 = res.retVal.datas
                this.total = res.retVal.totalRows
            })
        },
        toggleModal(url) {
            this.modalVisible = !this.modalVisible // 切换模态框的显示状态
            this.modalImage = url // 保存当前被点击的图片 URL
        },
    },
}
</script>

<style scoped>

</style>